<template>
  <div>
    <form>
      <v-row>
        <v-col class="text-left p-0">
          <label for="payment-method-radio-group">
            {{ $t('portalfrontendApp.coreFunding.depositTab.fundingMethod') }}
          </label>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="p-0 mb-8">
          <select-payment-method
            :payment-sources="paymentMethods"
            :disable-adding-payments="!addingFundsToOwnAccount"
            @add-bank-account="$emit('add-bank-account')"
            @add-credit-card="$emit('add-credit-card')"
            @payment-source-changed="setSelectedMethod"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-left p-0">
          <label for="funding-amount-input">
            {{ $t('portalfrontendApp.coreFunding.depositTab.fundingAmount') }}
          </label>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="p-0">
          <validation-provider
            v-slot="{errors, failedRules, flags: { touched, dirty }}"
            ref="amountValidation"
            name="funding amount"
            immediate
            vid="depositFundsAmountValidation"
            :rules="fundingAmountRules"
          >
            <v-text-field
              id="funding-amount"
              v-model="fundingAmount"
              class="pt-0 mt-0"
              type="number"
              dense
              outlined
              :error-messages="touched || dirty ? getCustomErrorMessage({errors, failedRules}) : []"
              @blur="trackSegmentValidationEvent(errors)"
            />
          </validation-provider>
        </v-col>
      </v-row>

      <v-row
        v-if="cardPaymentMethodSelected"
        class="totals-text"
      >
        <v-col class="text-left font-weight-medium p-0">
          <span id="fee-percent">
            {{ $t('portalfrontendApp.coreFunding.depositTab.fee') }} ({{ creditCardFeePercent }}%)
          </span>
          <v-tooltip right>
            <template #activator="{ on, attrs }">
              <v-icon
                class="funding-account-tooltip"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ $t('portalfrontendApp.coreFunding.depositTab.ccFee') }}
          </v-tooltip>
        </v-col>
        <v-col class="text-right font-weight-medium p-0 mb-3">
          <span id="fee-amount">
            {{ $moneyIntl(creditCardFeeAmount, currencyCode) }}
          </span>
        </v-col>
      </v-row>

      <v-row
        v-if="!cardPaymentMethodSelected"
        class="pending-message mt-2"
      >
        <v-col
          cols="1"
          class="font-weight-medium p-0 mr-3"
        >
          <v-icon
            medium
          >
            mdi-information-outline
          </v-icon>
        </v-col>
        <v-col class="p-0 mb-3 text-left">
          <p
            id="pending-title"
            class="font-weight-medium font-size-1"
          >
            {{ $t('portalfrontendApp.coreFunding.depositTab.pendingTitle') }}
          </p>
          <div
            class="font-size-12"
          >
            {{ $t('portalfrontendApp.coreFunding.depositTab.pendingMessage') }}
          </div>
        </v-col>
      </v-row>

      <v-row class="totals-text">
        <v-col class="text-left font-weight-medium p-0">
          {{ $t('portalfrontendApp.coreFunding.depositTab.totalPaymentAmount') }}
        </v-col>
        <v-col class="text-right font-weight-medium p-0">
          <span id="total-amount">
            {{ $moneyIntl(totalAmount, currencyCode) }}
          </span>
        </v-col>
      </v-row>
    </form>

    <form
      id="achRequestForm"
      ref="achRequestForm"
      :action="achApiFundUrl"
      method="post"
      hidden
    >
      <v-text-field
        v-for="(field, index) in getKeys(formData)"
        :id="`form-${field}`"
        :key="`${field}-${index}`"
        :name="field"
        :label="field"
        :value="formData[field]"
        type="text"
      />
    </form>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { ACH_REDIRECT_TO_ROUTE, PaymentSourceType } from '@/components/platform/funding/FundingConstants'
import { getAchAccessKey, getAchApiFundUrl, getAchProfileId } from '@/components/shared/rgfe-api'
import { ECHECK_SEC_CODE, MAX_VALUE_RULE, USD_CURRENCY_CODE } from '@/components/platform/funding/ach/constants'
import { FUNDING_TRANSACTION } from '@/components/platform/funding/ach/FundingTypes'
import { mapActions, mapGetters } from 'vuex'
import { UPDATE_THE_SELECTED_ACCOUNT } from '@/store/action-types'
import {
  CC_DEPOSIT_FUNDS_STATUS,
  FUNDING_DEPOSIT_SUCCESS,
  FUNDING_DIALOG_VALIDATION_ERROR
} from '@/components/shared/segment/track-funding'
import SelectPaymentMethod
  from '@/components/platform/orders/phys-order-flow/checkout/payment-method/SelectPaymentMethod'
import { PAYMENT_ERROR_DISPLAYED } from '@/components/shared/segment/track-place-order'
import { RG_ACH_REDESIGN } from '@/components/shared/split/split-constants'
import { ERROR, SUCCESS, WARNING } from '@/components/shared/alert/snack-constants'
import { GET_SELECTED_CUSTOMER_ACCOUNT } from '@/store/get-types'
import roundHalfEven from 'round-half-even'

export default {
  name: 'DepositFundsTab',
  components: {
    SelectPaymentMethod,
    ValidationProvider
  },
  props: {
    creditCards: {
      type: Array,
      required: true
    },
    achAccounts: {
      type: Array,
      required: true
    },
    selectedCustomerAccount: {
      type: Object,
      required: true
    },
    currentPlatform: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      selectedPaymentMethod: undefined,
      fundingAmount: undefined,
      achFundingLimit: null,
      minRuleAmount: 1,
      defaultCardFee: 0.035,
      formData: {},
      achBasePrefix: 'portalfrontendApp.coreAch.snackBarAlertMessages'
    }
  },

  computed: {
    ...mapGetters({
      currentGroupAccount: GET_SELECTED_CUSTOMER_ACCOUNT
    }),
    fundingLimit () {
      return this.bankPaymentMethodSelected
        ? this.achFundingLimit
        : null // TODO: RG-8386 - add cc limit here
    },
    achRedesignSplitEnabled () {
      return this.$isSplitEnabled(RG_ACH_REDESIGN)
    },
    fundingAmountRules () {
      // TODO: RG-8386 - can be single string without ternary
      return `required|min_value:${this.minRuleAmount}` + (this.fundingLimit ? `|max_value:${this.fundingLimit}` : '')
    },
    signingUrl () {
      return `api/ach/funding/account/${this.selectedCustomerAccount?.accountID}/subscription/${this.selectedPaymentMethod?.identifier}/request`
    },
    accountHasCredit () {
      return this.selectedCustomerAccount?.creditLimit > 0
    },
    showAchOptions () {
      return this.currencyCode === USD_CURRENCY_CODE && !this.accountHasCredit
    },
    achApiFundUrl () {
      return getAchApiFundUrl()
    },
    signedFields () {
      return 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,' +
        'echeck_account_type,echeck_sec_code,transaction_type,reference_number,amount,currency,payment_method,payment_token,' +
        'merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4,merchant_defined_data5,merchant_defined_data6,' +
        'merchant_defined_data7,merchant_defined_data8,merchant_defined_data9,merchant_defined_data10,merchant_defined_data11'
    },
    creditCardFee () {
      return this.currentPlatform?.accountFundingLimits?.creditCardFee ?? this.defaultCardFee
    },
    currencyCode () {
      return this.selectedCustomerAccount?.currencyCode
    },
    customErrorMessages () {
      return {
        max_value: this.$t(
          'portalfrontendApp.coreFunding.depositTab.validationMessages.limit',
          { maxAmount: this.$moneyIntl(this.fundingLimit, this.currencyCode) }
        ),
        min_value: this.$t(
          'portalfrontendApp.coreFunding.transferTab.validationMessages.minValue',
          { minAmount: this.$moneyIntl(this.minRuleAmount, this.currencyCode) }
        )
      }
    },
    paymentMethods () {
      const cardPaymentMethods = this.creditCards.map(card => {
        card.displayName = this.paymentLabel(card)
        card.paymentMethod = PaymentSourceType.CREDIT_CARD
        return card
      }).sort((a, b) => a.displayName.localeCompare(b.displayName))
      let bankPaymentMethods = []
      if (this.showAchOptions) { // Only support ach with accounts in USD
        bankPaymentMethods = this.achAccounts.map(bank => {
          bank.displayName = this.paymentLabel(bank)
          bank.paymentMethod = PaymentSourceType.ACH_BANK_ACCOUNT
          return bank
        }).sort((a, b) => a.displayName.localeCompare(b.displayName))
      }
      return [...bankPaymentMethods, ...cardPaymentMethods]
    },
    roundedFundingAmount () {
      return this.fundingAmount ? Number(this.fundingAmount).toFixed(2) : '0.00'
    },
    totalAmount () {
      const cardFee = this.cardPaymentMethodSelected ? Number(this.creditCardFeeAmount) : 0
      return this.round(Number(this.roundedFundingAmount) + cardFee, 2)
    },
    creditCardFeePercent () {
      return Number(+this.creditCardFee * 100).toFixed(2)
    },
    creditCardFeeAmount () {
      const feeAmount = this.round(this.roundedFundingAmount * this.creditCardFee, 2)
      return Number(feeAmount)
    },
    cardPaymentMethodSelected () {
      return this.selectedPaymentMethod?.paymentMethod === PaymentSourceType.CREDIT_CARD
    },
    bankPaymentMethodSelected () {
      return this.selectedPaymentMethod?.paymentMethod === PaymentSourceType.ACH_BANK_ACCOUNT
    },
    addingFundsToOwnAccount () {
      return this.selectedCustomerAccount.accountIdentifier === this.currentGroupAccount.accountIdentifier
    }
  },
  async beforeMount () {
    const achLimit = await this.getAchLimit()
    this.achFundingLimit = achLimit?.dailyUsageLimit
  },
  methods: {
    ...mapActions({
      updateSelectedAccount: UPDATE_THE_SELECTED_ACCOUNT
    }),
    async getAchLimit () {
      try {
        const response = await this.$http.get(
          `api/ach/account/${this.currentGroupAccount?.accountID}/limits`)
        return response.data
      } catch (err) {
        console.log(err)
      }
    },
    emitProcessingFunding () {
      this.$emit('processing-funding')
    },
    emitFinishedProcessingFunding () {
      this.$emit('finished-funding')
    },
    getKeys (formData) {
      return Object.keys(formData)
    },
    round (input, precision) {
      const truncInput = Math.trunc(input * Math.pow(10, precision + 1)) / Math.pow(10, precision + 1)
      return (+roundHalfEven(truncInput, precision)).toFixed(precision)
    },
    getCustomErrorMessage ({ errors = [], failedRules = {} } = {}) {
      const [failedRule] = Object.keys(failedRules)
      const [firstError] = errors
      const { [failedRule]: errorMessage } = this.customErrorMessages
      if (failedRule === MAX_VALUE_RULE) {
        this.$segment.track(PAYMENT_ERROR_DISPLAYED, { message: errorMessage })
      }
      return errorMessage || firstError
    },
    trackSegmentValidationEvent (errors) {
      if (errors.length) { this.$segment.track(FUNDING_DIALOG_VALIDATION_ERROR) }
    },
    paymentLabel (method) {
      const ccLastFour = this.lastFourSubstring(method.lastFourDigits)
      const achLastFour = this.lastFourSubstring(method.accountNumberLastFour)
      const lastFour = ccLastFour || achLastFour || ''
      return `${method.label} *${lastFour}`
    },
    lastFourSubstring (accountNumber) {
      return accountNumber?.substring(accountNumber.length, accountNumber.length - 4)
    },
    setSelectedMethod (method) {
      this.selectedPaymentMethod = method
    },
    submitForm () {
      this.$refs.amountValidation.validate().then((resp) => {
        if (resp.valid) {
          this.$emit('close-dialog')
          this.$modal.show({
            title: this.$t('portalfrontendApp.coreFunding.depositTab.confirmTitle'),
            message: this.$t('portalfrontendApp.coreFunding.depositTab.confirmMessage', {
              amount: this.$moneyIntl(this.totalAmount, this.currencyCode),
              paymentMethod: this.selectedPaymentMethod.displayName
            }),
            okTitle: this.$t('entity.action.confirm'),
            cancelTitle: this.$t('entity.action.cancel'),
            okCallback: this.onConfirm.bind(this),
            cancelCallback: () => this.$emit('open-dialog'),
            okDisabled: false,
            centered: true,
            isActive: true,
            noCloseOnEscape: true,
            noCloseOnBackDrop: true,
            hideHeaderClose: true
          })
        }
      })
    },
    onConfirm () {
      if (this.cardPaymentMethodSelected) {
        this.submitCardFunding()
      } else if (this.bankPaymentMethodSelected) {
        this.submitBankFunding()
      }
      this.$segment.track(FUNDING_DEPOSIT_SUCCESS, { total: this.fundingAmount })
    },
    displayMessaging (message, type) {
      if (this.achRedesignSplitEnabled) {
        this.$snack(message, false, type, type === ERROR ? -1 : null)
      } else {
        this.$toast(message, type === ERROR ? 'danger' : type)
      }
    },
    getAndDisplayAchErrorMessage (error) {
      const errorMessage = this.getAchErrorMessage(error)
      this.displayMessaging(errorMessage, ERROR)
    },
    getAchErrorMessage (error) {
      if (this.achRedesignSplitEnabled) {
        return error?.response?.status === 422
          ? this.$t(`${this.achBasePrefix}.exceedsDailyAchLimit`)
          : this.$t(`${this.achBasePrefix}.fundingRequestFailed`)
      } else {
        return this.$httpError(error)
      }
    },
    submitCardFunding () {
      this.emitProcessingFunding()
      if (this.achRedesignSplitEnabled) {
        this.$snack('Processing funding request...', true)
      }
      const criteria = {
        orderSource: 'PO',
        customerIdentifier: this.selectedPaymentMethod.customerIdentifier,
        accountIdentifier: this.selectedPaymentMethod.accountIdentifier,
        creditCardToken: this.selectedPaymentMethod.token,
        amount: this.roundedFundingAmount
      }

      return this.$http.post('api/creditCards/platforms/deposits', criteria)
        .then((res) => {
          const coreCreditCardDeposit = res.data
          this.$segment.track(CC_DEPOSIT_FUNDS_STATUS, { status: coreCreditCardDeposit.status })
          if (coreCreditCardDeposit.status === 'SUCCESS') {
            const message = this.achRedesignSplitEnabled
              ? this.$t(`${this.achBasePrefix}.fundingRequestSubmitted`)
              : this.$t('portalfrontendApp.coreFunding.depositTab.success')
            this.displayMessaging(message, SUCCESS)
            this.updateSelectedAccount()
          } else if (coreCreditCardDeposit.status === 'PENDING') {
            this.displayMessaging(this.$t('portalfrontendApp.coreFunding.creditCardDeposit.pending'), WARNING)
          } else if (coreCreditCardDeposit.status === 'PENDING_KYB') {
            this.displayMessaging(this.$t('portalfrontendApp.coreFunding.creditCardDeposit.pendingKyb'), WARNING)
          } else {
            const message = this.achRedesignSplitEnabled
              ? this.$t(`${this.achBasePrefix}.fundingRequestFailed`)
              : this.$t('portalfrontendApp.coreFunding.depositTab.error')
            this.displayMessaging(message, ERROR)
          }
        })
        .catch((error) => {
          const response = error.response || {}
          const isDailyLimitExceeded = response.status === 422 && response.data?.i18nKey === '403.039'
          const isAboveApiValidationAmount = response.status === 400 && response.data?.i18nKey === 'creditCardDeposit.error.validation.amount'
          let message = this.$t('portalfrontendApp.coreFunding.depositTab.error')
          if (isDailyLimitExceeded || isAboveApiValidationAmount) {
            message = this.$t(`${this.achBasePrefix}.ccFundingRequestExceedsLimit`)
          } else if (this.achRedesignSplitEnabled) {
            message = this.$t(`${this.achBasePrefix}.fundingRequestFailed`)
          }
          this.displayMessaging(message, ERROR)
        })
        .finally(() => {
          this.emitFinishedProcessingFunding()
        })
    },
    submitBankFunding () {
      this.emitProcessingFunding()
      if (this.achRedesignSplitEnabled) {
        this.$snack('Processing funding request...', true)
      }
      // RG-6604 redirect from cybersource happens but, we want to redirect with a beforeEnter
      // based on previous route in localstorage
      localStorage.setItem(ACH_REDIRECT_TO_ROUTE, this.$route.name)
      const dtoToBeSigned = {
        access_key: getAchAccessKey(),
        amount: this.roundedFundingAmount,
        currency: USD_CURRENCY_CODE,
        echeck_account_type: 'C',
        echeck_sec_code: ECHECK_SEC_CODE,
        locale: 'en',
        payment_method: 'echeck',
        profile_id: getAchProfileId(),
        signed_field_names: this.signedFields,
        transaction_type: FUNDING_TRANSACTION,
        unsigned_field_names: '' // intentionally left blank
      }

      this.$http.post(this.signingUrl, dtoToBeSigned)
        .then(response => {
          // combine response with original, we don't send the bank info to our servers
          // formData is different from this.model because we're adjusting the data and we don't want it to flicker on the page
          this.formData = { ...dtoToBeSigned, ...response.data }
        })
        .then(() => {
          // why another "then"? We have to wait for the dom to finish setting the new values.
          this.$refs.achRequestForm.submit()
          this.updateSelectedAccount()
        })
        .catch((err) => {
          this.getAndDisplayAchErrorMessage(err)
          this.emitFinishedProcessingFunding()
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/theme';

.totals-text {
  color: var(--t-color-text);
}
.pending-message{
  background-color:#E4E3EF;
  border-radius:0.5rem;
  padding: 0.75rem;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  .v-icon {
    color:var(--t-color-text);
  }
}
</style>
