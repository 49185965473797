<template>
  <v-row
    class="tc-banner-message mt-2 mb-0"
    :align="getAlignment"
    :class="`message-${bannerMessage.type}`"
  >
    <v-col
      v-if="bannerMessage.title"
      class="font-weight-medium p-0 mr-0 text-center icon-col"
      :class="{'mb-0': !bannerMessage.description}"
    >
      <v-icon
        medium
      >
        {{ bannerMessage.icon || 'mdi-information-outline' }}
      </v-icon>
    </v-col>
    <v-col
      v-if="bannerMessage.title"
      class="p-0 text-left message-title"
      :class="{'mb-3': bannerMessage.description && !hasBottomButtons}"
    >
      <p
        id="banner-message-title"
        class="font-weight-medium font-size-1"
        :class="{'mb-0': !bannerMessage.description}"
      >
        {{ bannerMessage.title.trim() }}
      </p>
      <div
        v-if="bannerMessage.description"
        id="banner-message-description"
        class="font-size-12"
      >
        <div v-sanitize="bannerMessage.description.trim()" />
      </div>
      <div
        v-if="hasBottomButtons && hasRescheduleSplitEnabled"
        class="banner-bottom-btns mt-2"
      >
        <v-btn
          v-for="(button, index) in bottomButtons"
          :key="index"
          text
          class="tc-banner-bottom-btn font-weight-medium font-size-1 pa-0 ma-0"
          @click="button.onClick"
        >
          {{ button.text }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { BannerMessageTypes } from '@/components/authentication/enum/BannerMessageType'
import { RG_8280_RESCHEDULE_DELIVERY_TIME } from '@/components/shared/split/split-constants'

export default {
  name: 'TcBannerMessage',
  props: {
    bannerMessage: {
      type: Object,
      default: () => ({
        title: {
          type: String,
          default: ''
        },
        description: {
          type: String,
          default: ''
        },
        type: {
          type: String,
          default: BannerMessageTypes.WARNING
        }
      })
    },
    bottomButtons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getAlignment () {
      if (this.hasBottomButtons && this.hasRescheduleSplitEnabled) {
        return 'start'
      }
      if (!this.bannerMessage.description && this.bannerMessage.title) {
        return 'start'
      }
      if (!this.bannerMessage.description || !this.bannerMessage.title) {
        return 'center'
      }
      return 'start'
    },
    hasBottomButtons () {
      return this.bottomButtons.length > 0
    },
    hasRescheduleSplitEnabled () {
      return this.$isSplitEnabled(RG_8280_RESCHEDULE_DELIVERY_TIME)
    }
  }
}
</script>

<style scoped lang="scss">

.tc-banner-message{
  background-color: var(--t-color-primary-weakest);
  border-radius:0.5rem;
  padding: 0.75rem;
  margin: 1.5rem auto;
  white-space:normal;
  &.message-warning{
    background-color: var(--t-color-status-warning-weak);
  }
  &.message-error{
    background-color: var(--t-color-status-danger-weakest);
  }
  &.message-info{
    background-color: var(--t-color-primary-weakest);
  }
  &.message-success{
    background-color: var(--t-color-status-success-weakest);
  }
  .v-icon {
    color:var(--t-color-text);
  }
  ::v-deep a{
    font-weight: var(--t-font-weight-5) !important;
    color: var(--t-color-text-link) !important;;
    text-decoration: underline !important;;
  }
}

.icon-col{
  width:35px;
  max-width: 35px;
  flex-grow: 1;
}

.message-title{
  height: auto;
}

.login-error{
  padding-left:32px;
}

.banner-bottom-btns {
  display: flex;
  gap: 20px;
}

.tc-banner-bottom-btn {
  color: var(--t-color-text);
  cursor: pointer;
  text-decoration: underline;
}
</style>
