<template>
  <!-- Chrome only respects autocomplete="off" when input type is "search" -->
  <v-text-field
    ref="address"
    v-model="addressModel"
    autocomplete="off"
    class="address-autocomplete"
    dense
    :error-messages="errorMessages"
    hide-details="auto"
    outlined
    :placeholder="placeholder"
    type="search"
    @blur="handleBlur"
    @focus="handleFocus"
    @input="handleInput"
  />
</template>

<script>
import { find } from 'lodash'
import { ADDRESS_AUTOCOMPLETE_USED } from '@/components/shared/constants/sessionstorage.constants'

export default {
  name: 'AddressAutocomplete',

  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Address'
    },
    countries: {
      type: Array,
      default: () => []
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      // NOTE: We don't actually make use of this value but it's used for a better user experience. Without it, if the
      // user enters "123", selects an address, and then goes back to edit the address the input would only contain the
      // value "123". With it, the input contains the value "123 Street City, State, Country".
      //
      // I believe this is because Google Maps and Vuetify are fighting for control over the input. GMaps correctly sets
      // the value but when you click it, Vuetify overrides it with its own value (123) so this data field ensures the
      // vuetify component is up to speed with the latest data.
      formattedAddress: this.value ?? '',
      usedAutocomplete: false,
      isFocused: false
    }
  },

  computed: {
    addressModel: {
      get () {
        return this.showBlankInput ? '' : this.formattedAddress
      },
      set (newAddress) {
        this.formattedAddress = newAddress
      }
    },
    inputEl () {
      return this.$refs.address.$el.querySelector('input')
    },
    showBlankInput () {
      return !this.isFocused && !this.usedAutocomplete
    }
  },

  async mounted () {
    // load flag from session storage of whether they used address autocomplete or not
    const storedUsedAutocomplete = sessionStorage.getItem(ADDRESS_AUTOCOMPLETE_USED)
    this.usedAutocomplete = storedUsedAutocomplete === 'true'

    try {
      await this.googlePlaceLoaded()
      const { google } = window
      const options = {
        componentRestrictions: {
          country: this.countries
        },
        types: ['geocode']
      }
      this.autocomplete = new google.maps.places.Autocomplete(this.inputEl, options)
      this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    } catch (error) {
      console.error('Could not load Google Map API integration, caught: ', error)
    }
  },

  methods: {
    handleBlur (event) {
      this.isFocused = false
      this.$emit('blur', event)
    },
    handleFocus (event) {
      this.isFocused = true
      this.$emit('focus', event)
    },
    handleInput (event) {
      this.setAddressAutocompleteUsed(false)
      this.$emit('input', event)
    },
    placeChanged () {
      this.setAddressAutocompleteUsed(true)
      const { address_components: components } = this.autocomplete.getPlace()

      const street = this.getField(components, 'route', 'long_name') || ''
      const streetNumber = this.getField(components, 'street_number', 'short_name') || ''
      const streetLine1 = `${streetNumber} ${street}`.trim()
      const locality = this.getField(components, 'locality', 'short_name') || ''
      const subLocality = this.getField(components, 'sublocality_level_1', 'short_name') || ''
      const neighborhood = this.getField(components, 'neighborhood', 'short_name') || ''
      const city = locality || subLocality || neighborhood
      const stateOrProvince = this.getField(components, 'administrative_area_level_1', 'short_name') || ''
      const postalCodePrefix = this.getField(components, 'postal_code', 'long_name') || ''
      const postalCodeSuffix = this.getField(components, 'postal_code_suffix', 'long_name') || ''
      const postalCode = postalCodePrefix + (postalCodeSuffix ? `-${postalCodeSuffix}` : '')
      const country = this.getField(components, 'country', 'short_name') || ''

      this.$emit('change', {
        streetLine1,
        city,
        stateOrProvince,
        postalCode,
        country
      })
      // Google Maps sets the input to a formatted address but Vuetify doesn't have that value so set this data field to
      // tell Vuetify what the input's value should be
      this.formattedAddress = this.$refs.address.$refs.input.value
    },
    getField (components, type, field) {
      const component = find(components, c => c.types.includes(type)) || {}
      return component[field]
    },
    async googlePlaceLoaded () {
      return new Promise((resolve, reject) => {
        const key = process.env.VUE_APP_GOOGLE_MAPS_API_KEY
        const e = document.createElement('script')
        e.type = 'text/javascript'
        e.async = true
        e.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`

        if (document.querySelector('script[src="' + e.src + '"]')) {
          // resolve the promise if it already exists
          resolve()
        } else if (typeof key === 'undefined') {
          // don't allow adding the script to the DOM if the key is undefined
          console.error('key is undefined!')
          resolve()
        } else {
          // add the google places script to the DOM
          e.addEventListener('load', resolve)
          e.addEventListener('error', reject)
          e.addEventListener('abort', reject)
          document.body.appendChild(e)
        }
      })
    },
    setAddressAutocompleteUsed (addressAutocompleteUsed) {
      this.usedAutocomplete = addressAutocompleteUsed
      sessionStorage.setItem(ADDRESS_AUTOCOMPLETE_USED, addressAutocompleteUsed)
    }
  }
}
</script>

<style scoped>
  ::v-deep .v-text-field__slot {
    font-size: var(--t-font-size-1);
  }

  .address-autocomplete {
    border: 0;
  }
</style>
