import * as splitConstants from './split-constants'

// All local splits are 'on' or 'off' unless otherwise specified
export default {
  [splitConstants.API_KEY_MANAGEMENT]: 'on',
  [splitConstants.OAUTH_CLIENT_CRED_MGMT]: 'on',
  [splitConstants.KBP_BRANDS_QR_CODE]: 'on_v2',
  [splitConstants.KBP_BRANDS_BYPASS_EMAIL]: 'off',
  [splitConstants.ORDER_SETTINGS]: 'off',
  [splitConstants.POR_1968_RG_REFERRAL_PROGRAM]: 'off',
  [splitConstants.QUALTRICS_SPLIT]: 'on',
  [splitConstants.REWARD_HISTORY]: 'off',
  [splitConstants.RG_4306_RG_ROP_UPLOAD_STATUS]: 'on',
  [splitConstants.RG_ACH_DRAW]: 'on',
  [splitConstants.RG_ACH_REDESIGN]: 'on',
  [splitConstants.RG_BULK_ORDER_LARGER_RAW_FILE_SIZE]: 'on',
  [splitConstants.RG_BULK_ORDER_LIMITS]: 'off',
  [splitConstants.RG_7504_REISSUE_EXPIRED_PROMO_LINK_PERMISSION]: 'on',
  [splitConstants.RG_DOWNLOAD_LINE_ITEMS]: 'on',
  [splitConstants.RG_FUNDING_NOTIFICATIONS]: 'off',
  [splitConstants.RG_ORDER_HISTORY_TYPE]: 'off',
  [splitConstants.RG_ORDER_HISTORY_TYPE]: 'off',
  [splitConstants.RG_PERSONALIZATION_TO_FROM_SEARCH]: 'on',
  [splitConstants.RG_RECIPE_ENGINE_ASYNC_ORDERING]: 'on',
  [splitConstants.RG_RECIPIENTS_WHITELIST_FEATURE]: 'off',
  [splitConstants.RG_SAML_SSO_UI]: 'on',
  [splitConstants.WYNDHAM_CUSTOM_FIELDS]: 'off',
  [splitConstants.RG_SHOW_BULK_RESEND]: 'on',
  [splitConstants.RG_SELF_SIGNUP]: 'on',
  [splitConstants.RG_INCREASED_ORDER_HISTORY_PAGE_SIZE]: 'control',
  [splitConstants.RG_ORDER_DIALOG]: 'off',
  [splitConstants.RG_7028_PROMO_LINK_EXPIRATION_HISTORY]: 'on',
  [splitConstants.RG_7080_TANGO_COMPANION_APP]: 'on',
  [splitConstants.RG_7198_REMOVE_403_IGNORE_LIST]: 'on',
  [splitConstants.RG_EASY_USE_CASE]: 'on',
  [splitConstants.RG_7282_TANGO_PRODUCT_OFFERINGS]: 'on',
  [splitConstants.RG_7482_TANGO_UTID_DIALOG]: 'on',
  [splitConstants.WYNDHAM_POPUP]: 'on',
  [splitConstants.WYNDHAM_OPTIN_MESSAGE]: 'on',
  [splitConstants.MOBILE_DELIVERY_MVP]: 'on',
  [splitConstants.ENABLE_CART_BRAND_VIEW]: 'off',
  [splitConstants.PORTAL_LINE_ITEMS_SHOW_REMAINING_BALANCE]: 'off',
  [splitConstants.RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER]: 'on',
  [splitConstants.RG_EDIT_CART_DIALOG]: 'on',
  [splitConstants.RG_ACCOUNT_REDESIGN]: 'on',
  [splitConstants.AA_212_SSO_CONNECTIONS]: 'on',
  [splitConstants.ACH_ACCOUNT_VALIDATION]: 'on',
  [splitConstants.CHAMELEON_SPLIT]: 'on',
  [splitConstants.AUTHENTICATED_SALESFORCE]: 'on',
  [splitConstants.RG_8006_CASE_MANAGEMENT_PERMISSION]: 'on',
  [splitConstants.AA_254_USER_MFA]: 'off',
  [splitConstants.RG_7991_SHOPPING_CART_INTEGRATION]: 'off',
  [splitConstants.SCHEDULED_DELIVERY]: 'on',
  [splitConstants.SCHEDULED_DELIVERY_BUTTON]: 'on',
  [splitConstants.OUT_OF_STOCK]: 'on',
  [splitConstants.INTEGRATION_CREDENTIALS]: 'on',
  [splitConstants.QUALTRICS_NEW_TABLE]: 'on',
  [splitConstants.CASE_MANAGEMENT_ENABLE_MANAGE_PERMISSION]: 'on',
  [splitConstants.RG_8317_NEW_ORDERS_TABLE]: 'on',
  [splitConstants.RG_8313_FUNDING_HISTORY_TABLE]: 'on',
  [splitConstants.RG_8310_NEW_REPORTS_TABLE]: 'on',
  [splitConstants.RG_8280_RESCHEDULE_DELIVERY_TIME]: 'on',
  [splitConstants.RG_8341_NEW_EMAIL_TEMPLATES_TABLE]: 'on',
  [splitConstants.RG_8374_NEW_EMAIL_TEMPLATE_HEADER_IMAGE]: 'on',
  [splitConstants.RECIPIENT_UPLOAD_MAPPER]: 'on',
  [splitConstants.RG_8284_INVOICE_ON_FUNDING_PAGE]: 'on',
  [splitConstants.RG_8402_SIGNUP_100K_TOGGLE]: 'on'
}
