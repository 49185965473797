// Permanent Splits

// Platform
export const RG_RECIPIENTS_WHITELIST_FEATURE = 'recipient-whitelist'
export const WYNDHAM_CUSTOM_FIELDS = 'Wyndham_Custom_Fields'
export const RG_INCREASED_ORDER_HISTORY_PAGE_SIZE = 'RG-Increased-Order-History-Page-Size'
export const RG_7504_REISSUE_EXPIRED_PROMO_LINK_PERMISSION = 'RG-7504-Reissue-Expired-Promo-Link-Permission'

// Temporary Splits

// Platform
export const API_KEY_MANAGEMENT = 'API-Key-MGMT'
export const OAUTH_CLIENT_CRED_MGMT = 'OAuth-Client-Cred-MGMT'
export const KBP_BRANDS_QR_CODE = 'KBP_Brands_QRCode'
export const KBP_BRANDS_BYPASS_EMAIL = 'KBP_Brands_Bypass_Email'
export const ORDER_SETTINGS = 'Order-Settings'
export const POR_1968_RG_REFERRAL_PROGRAM = 'POR-1968_rg_referral_program'
export const QUALTRICS_SPLIT = 'rg-qualtrics'
export const REWARD_HISTORY = 'Reward-History'
export const RG_ACH_DRAW = 'RG-ACH-Draw'
export const RG_ACH_REDESIGN = 'RG-7416-ACH-redesign'
export const RG_BULK_ORDER_LARGER_RAW_FILE_SIZE = 'RG-Bulk-Order-Larger-Raw-File-Size'
export const RG_BULK_ORDER_LIMITS = 'RG-Bulk-Order-Limits'
export const RG_DOWNLOAD_LINE_ITEMS = 'RG-6073-Download-Line-Items'
export const RG_FUNDING_NOTIFICATIONS = 'RG-Funding-Notifications'
export const RG_ORDER_HISTORY_TYPE = 'rg-order-history-action-type'
export const RG_PERSONALIZATION_TO_FROM_SEARCH = 'RG-Personalization-To-From-Search'
export const RG_RECIPE_ENGINE_ASYNC_ORDERING = 'rg-recipe-engine-async-ordering'
export const RG_SAML_SSO_UI = 'RG-SAML-SSO-UI'
export const RG_SHOW_BULK_RESEND = 'RG-SHOW-BULK-RESEND'
export const RG_ORDER_DIALOG = 'rg-order-dialog'
export const RG_7028_PROMO_LINK_EXPIRATION_HISTORY = 'RG-7028-Promo-Link-Expiration-History'
export const RG_7080_TANGO_COMPANION_APP = 'RG_7080_Tango_Companion_App'
export const RG_7198_REMOVE_403_IGNORE_LIST = 'RG-7198-remove-403-ignore-list'
export const RG_7282_TANGO_PRODUCT_OFFERINGS = 'RG_7282_TANGO_PRODUCT_OFFERINGS'
export const RG_7482_TANGO_UTID_DIALOG = 'RG_7482_Tango_Utid-Dialog'
export const WYNDHAM_POPUP = 'Wyndham_Popup'
export const WYNDHAM_OPTIN_MESSAGE = 'Wyndham_OptIn_Message'
export const MOBILE_DELIVERY_MVP = 'wyndham_mobile_delivery_mvp'
export const ENABLE_CART_BRAND_VIEW = 'RL-7718-enable-cart-brand-view'
export const PORTAL_LINE_ITEMS_SHOW_REMAINING_BALANCE = 'portal-line-items-show-remaining-balance'
export const RG_EDIT_CART_DIALOG = 'rg-edit-cart-dialog'
export const RG_ACCOUNT_REDESIGN = 'AA-361-ACCOUNT-REDESIGN'
export const AA_212_SSO_CONNECTIONS = 'AA-212-SSO-Connections'
export const ACH_ACCOUNT_VALIDATION = 'ach-account-validation'
export const AUTHENTICATED_SALESFORCE = 'authenticated-salesforce'
export const RG_8006_CASE_MANAGEMENT_PERMISSION = 'RG-8006-case-management-permission'
export const AA_254_USER_MFA = 'AA-254-USER-MFA'
export const RG_7991_SHOPPING_CART_INTEGRATION = 'RG-7991-shopping-cart-integration'
export const SCHEDULED_DELIVERY = 'RG-8044-Scheduled-Delivery'
export const SCHEDULED_DELIVERY_BUTTON = 'RG-8334-Scheduled-Delivery-Button'
export const OUT_OF_STOCK = 'CONTENT-2443-add-item-available-to-catalog-search'
export const INTEGRATION_CREDENTIALS = 'Integration_Credentials'
export const CASE_MANAGEMENT_ENABLE_MANAGE_PERMISSION = 'portal-case-mgmt-enable-manage-permission'
export const RG_8317_NEW_ORDERS_TABLE = 'RG-8317-New-Orders-Table'
export const QUALTRICS_NEW_TABLE = 'RG-8312-New-Qualtrics-Table'
export const RG_8313_FUNDING_HISTORY_TABLE = 'RG-8313-New-Funding-History-Table'
export const RG_8310_NEW_REPORTS_TABLE = 'RG-8310-New-Reports-Table'
export const RG_8341_NEW_EMAIL_TEMPLATES_TABLE = 'RG-8341-New-Email-Templates-Table'
export const RG_8284_INVOICE_ON_FUNDING_PAGE = 'RG-8284-Invoice-On-Funding-Page'
export const RG_8280_RESCHEDULE_DELIVERY_TIME = 'RG-8280-Reschedule-Delivery-Time'
export const RG_8374_NEW_EMAIL_TEMPLATE_HEADER_IMAGE = 'RG-8374-New-Email-Template-Header-Image'
export const RECIPIENT_UPLOAD_MAPPER = 'RG-8350-Recipient-Upload-Mapper'

// Application
export const RG_4306_RG_ROP_UPLOAD_STATUS = 'RG-4306-RG-ROP-Upload-Status'
export const RG_SELF_SIGNUP = 'rg-self-signup'
export const RG_EASY_USE_CASE = 'rg-easy-use-case'
export const RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER = 'RG-use-line-item-table-for-specific-order'
export const CHAMELEON_SPLIT = 'rg-7872-chameleon'
export const RG_8402_SIGNUP_100K_TOGGLE = 'rg-8402-signup-100k-toggle'
