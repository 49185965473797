export const fundingHistoryHeader = [
  {
    value: 'createdAt',
    text: 'portalfrontendApp.coreFunding.history.date',
    sortable: true
  },
  {
    value: 'accountTransactionType.displayName',
    text: 'portalfrontendApp.coreFunding.history.type',
    sortable: false
  },
  {
    value: 'accountTransactionID',
    text: 'portalfrontendApp.coreFunding.history.transactionId',
    sortable: true
  },
  {
    value: 'amount',
    text: 'portalfrontendApp.coreFunding.history.amount',
    sortable: true
  },
  {
    value: 'currencyCode',
    text: 'portalfrontendApp.coreFunding.history.currency',
    sortable: false
  },
  {
    value: 'accountID',
    text: 'portalfrontendApp.coreFunding.history.accountNumber',
    sortable: true
  },
  {
    value: 'accountDisplayName',
    text: 'portalfrontendApp.coreFunding.history.accountName',
    sortable: false
  },
  {
    value: 'status',
    text: 'portalfrontendApp.coreFunding.history.status',
    sortable: true
  },
  {
    value: 'placeholder',
    // this value literally doesn't matter, use whatever
    text: '',
    sortable: false
  }
]

export const itemsPerPageOptions = [25, 50, 100, 250, 500]
export const statusPending = 'PENDING'
